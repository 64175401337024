import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from '../../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

// the new firebase works as below:
// import { signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import {Button} from 'antd'
import { GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import {useNavigate, Link } from 'react-router-dom';
import { login, createOrUpdateUser } from '../../functions/auth';
import Header from '../../components/nav/Header';

// the new firebase changes: see this link:
// https://blog.logrocket.com/user-authentication-firebase-react-apps/

const Login = () => {


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => ({ ...state }))
// console.log('------------------>>> navigate >::::<', navigate)
  useEffect(() => {
    let intended = navigate.location;
    if (intended) {
      return;
    } else {
    if (user && user.token) navigate(-1)
    }
  },[user])

    // console.log('----> navigate <-----------', navigate)
  const roleBasedRedirect = (res) => {
    //check if intended
    let intended = navigate.location;
    // console.log('----> intended <-----------', intended)
    if (intended) {
      navigate(intended.from);
    } else {
      if (res.data.role === 'admin') {
        navigate('/admin/dashboard');
      } if (res.data.isSeller) {
        navigate('/seller/dashboard');
      } else {
        navigate('/');
      }
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    setLoading(true);
    try {
      // the new firebase functions below:
      // await signInWithEmailAndPassword(auth, email, password);
      const result = await signInWithEmailAndPassword(auth, email, password);
     // console.log('Result:', result)
      const { user } = result;
      // console.log('user -------------->: ', user)
      // console.log('user getIdTokenResult -------------->: ', user && user.getIdTokenResult)
      const idTokenResult = await user.getIdTokenResult();
      // console.log('idTokenResult.token : ', idTokenResult.token)
      login(idTokenResult.token)
        .then((res) => {
          console.log('LOGIN User FE RES---->', res)
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name:res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                isSeller: res.data.isSeller,
                isAdmin: res.data.isAdmin,
                _id:res.data._id
              }
            });
          setEmail('')
          setPassword('')
          setLoading(false)
          // navigate(-1)
          roleBasedRedirect(res);

        })
        .catch((err) => console.log(err));

    } catch (error) {
      toast.error(error.message)
      setLoading(false)

    }
  }

  const googleLogin = async() => {
    try {
      //the new firebase:
      // await signInWithPopup(auth, googleAuthProvider)
      await signInWithPopup(auth, googleProvider)
        .then(async (result) => {
          const { user } = result;
          //console.table('user-->', user, 'display name:', user.displayName);
          const idTokenResult = await user.getIdTokenResult()
          createOrUpdateUser(idTokenResult.token)
            .then((res) => {
              dispatch({
                type: "LOGGED_IN_USER",
                payload: {
                  name: res.data.name,
                  email: res.data.email,
                  token: idTokenResult.token,
                  role: res.data.role,
                  _id: res.data._id,
                }
              })
              // navigate(-1)
              roleBasedRedirect(res);
            })
            .catch((err) => console.log(err));
        })
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const loginForm = () =>  <form onSubmit={handleSubmit} className='register-form' >
      <div className="form__input-register p-3">
        <input
          className="form-control p-2 "
          type="email"
          id='email'
          value={email}
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
          required

        />
        <label htmlFor="email" className="form__label">Your Email</label>
      </div>
      <br />
      <div className="form__input-register p-3">
        <input
          className="form-control p-2 "
          type="password"
          value={password}
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
          id='password'
          required
          autoFocus
        />
        <label htmlFor="password" className="form__label">Password</label>
      </div>
      <br />
      <Button onClick={handleSubmit}  className='btn--login' type='primary' shape='round' block icon={<MailOutlined />} size='medium'>
        Login with Email/Password
      </Button>
    </form>



  return (
    <div>
       <Header>
        {!user && (
          <>
            <h1 className="heading-primary">
          <span className='heading-primary--main'>  iDyaNova
          </span>
          <span className='heading-primary--sub'>No Distance is too far</span>
        </h1>

            <a href="/login" className='btn btn--white btn--animated'>Login</a>
          </>
        )}
      </Header>
      <div className="row-background-page">
        <div className="book  m-5 ">
          <div className="row-login-background-page" >

              {loading ? (<h1 className="heading--create-account-title">Loading...</h1>) : (
                <h2 className='heading--create-account-title'>Login</h2>
              )}
              {loginForm()}
              <div className="btn--login-google-forgot-password">
              <Button onClick={googleLogin} type='danger' size='large' icon={<GoogleOutlined style={{color:'red',}}/>}  className='btn--login-google' shape='round'>
                Login with Google
              </Button>
               <Link to='/forgot/password' className='text-center' style={{color:'#aab0ee'}}>
                Forgot password?
              </Link>
              </div>

        </div>
      </div>
      </div>
    </div>
  )
}
export default Login;
