import axios from "axios";


export const userGeneralCart = async (cart, productList, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/userGeneralCart`, { cart, productList}, {
    headers: {
      authToken
    }
  })



export const changeOrderStatus = async (orderId, orderStatus, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/user/order-status`, {orderId, orderStatus }, {
    headers: {
      authToken
    }
  });

export const orderShippingConfirmation = async (orderId, trackingShipppingNumber, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/user/tracking-confirm-number`, {orderId, trackingShipppingNumber }, {
    headers: {
      authToken
    }
  });

export const deliveryConfirmationPicts = async (orderId, values, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/user/delivery-confirm-images`, {orderId, values }, {
    headers: {
      authToken
    }
  });





export const updateUserToSeller = async (authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/isSeller`, {}, {
    headers: {
      authToken
    }
  })

export const getUserGeneralCart = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/general-cart`, {
    headers: {
      authToken
    }
  })

export const userCart = async (cart, shippingObj, shippingType, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/cart`, { cart, shippingObj, shippingType }, {
    headers: {
      authToken
    }
  })

// export const calculateTax = async (authToken) =>
//   await axios.post(`${process.env.REACT_APP_API}/user/calculate-tax`, { }, {
//     headers: {
//       authToken
//     }
//   })

export const getUserCart = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/cart`, {
    headers: {
      authToken
    }
  })


export const getLastOrder = async (sessionId, authToken) =>

  await axios.post(`${process.env.REACT_APP_API}/user/last-order`, {sessionId}, {
    headers: {
      authToken,
    }
  })

export const emptyUserCart = async (authToken) =>
  await axios.delete(`${process.env.REACT_APP_API}/user/cart`, {
    headers: {
      authToken
    }
  })

export const saveShippingAddress = async (shippingAddress, phone, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/address`, { shippingAddress, phone }, {
    headers: {
      authToken
    }
  })


export const applyCoupon = async (coupon, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/cart/coupon`, { coupon }, {
    headers: {
      authToken
    }
  })

export const createOrder = async (sessionId, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/order`, { sessionId }, {
    headers: {
      authToken
    }
  }
  );

// export const createOrderForCash = async ( coupon, COD, authToken) =>
//   await axios.post(`${process.env.REACT_APP_API}/user/cash-order`,
//     { couponApplied: coupon, COD },
//     {
//     headers: {
//         authToken
//       }
//     })

export const getUserOrders = async (authToken) =>

  await axios.get(`${process.env.REACT_APP_API}/user/orders`, {
    headers: {
      authToken
    }
  })

// export const getStripeSession = async (orderId, authToken) =>
//   await axios.get(`${process.env.REACT_APP_API}/user/order/details/${orderId}`, {
//     headers: {
//       authToken
//       }
//   })

export const getUserOrder = async (orderId, authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/order/details/${orderId}`, {
    headers: {
      authToken
      }
    })

export const addToWishlist = async (productId, authToken) =>
  await axios.post(`${process.env.REACT_APP_API}/user/wishlist`, { productId }, {
    headers: {
      authToken
    }
  })

export const removeFromWishlist = async (productId, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/user/wishlist/${productId}`, {},
    {
      headers: {
        authToken
      }
    }
  );

export const getWishlistSeller = async (seller) =>
  await axios.get(`${process.env.REACT_APP_API}/user/wishlist/seller/${seller}`);

export const getWishlist = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/wishlist`, {
    headers: {
      authToken
    }
  }
  );




export const profileUpdate = async (values, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/user/profile`, { values }, {
    headers: {
      authToken
    }
  })

export const sellerProfileUpdate = async (values,sellerLogo, sellerName, sellerDescription, authToken) =>
  await axios.put(`${process.env.REACT_APP_API}/seller/profile`, { values, sellerLogo, sellerName, sellerDescription}, {
    headers: {
      authToken
    }
  })

export const getSellerOrders = async (sellerId, authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/seller/orders/${sellerId}`, {
    headers: {
      authToken
    }
  });

export const getSellerDetails = async (sellerId) =>
  await axios.get(`${process.env.REACT_APP_API}/seller/details/${sellerId}`);

export const listTopSellers = async () =>
  await axios.get(`${process.env.REACT_APP_API}/users/top-sellers`);

