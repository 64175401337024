// import firebase from 'firebase'
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider, getAuth, signInWithEmailLink,
  // signInWithPopup,
  // signInWithEmailAndPassword,
  // createUserWithEmailAndPassword, sendPasswordResetEmail, signOut
} from "firebase/auth";
// import { getFirestore, query, getDocs, collection, where, addDoc } from "firebase/firestore";

// console.log('---- initializeApp ---->>', initializeApp)

  var firebaseConfig = {
    apiKey: "AIzaSyBk7Cv0XLocm5anC9hc_Zuezfl2jkPLnSw",
    authDomain: "ecom-master-mp.firebaseapp.com",
    projectId: "ecom-master-mp",
    storageBucket: "ecom-master-mp.appspot.com",
    messagingSenderId: "482201085986",
    appId: "1:482201085986:web:795ab9c99e70eb2aff427b"
};

  // Initialize Firebase,
//  firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
// const googleLogin = signInWithPopup()
// const db = getFirestore(auth)
const googleProvider = new GoogleAuthProvider();
// console.log('---- firebase ---->>', app)

//export:
// export const auth = firebase.auth();
export {
  auth, googleProvider, app
  // , googleLogin
  // , db
}
// export const googleAuthProvider = new auth.GoogleAuthProvider();


// See this: https://blog.logrocket.com/user-authentication-firebase-react-apps/
