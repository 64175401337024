import React, { useState, useEffect } from 'react'
// import { auth } from '../../firebase'
// import {getAuth, signInWithEmailLink, updatePassword } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {Button} from 'antd'
import { register } from '../../functions/auth'
import Header from '../nav/Header'
import PageTitle from '../PageTitle'




export const RegistrationCompleteComponent = ({
  auth, handleRegisterCompleteSubmit,
  name, setName, email, setEmail, password, setPassword,
  confirmPassword, setConfirmPassword }) => {

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => ({ ...state }))

  // const auth = getAuth();
  useEffect(() => {
    if (user && user.token) {
      navigate('/')
      }
    setEmail(window.localStorage.getItem('emailForRegistration'))
     if (window.localStorage.getItem('emailForRegistration'===null)) {

    setEmail(window.prompt('Please provide your email for confirmation'))
  }

  }, [user])


  // const completeRegisterForm = () => (

  // )

  return (

    <div className="row-background-page">
        {loading ? (<h1 className="heading--create-account-title">Loading...</h1>) : (
          <h2 className='heading--create-account-title'>Complete Registration</h2>
        )}

          <form onSubmit={handleRegisterCompleteSubmit} className='register-form'>
            <div className="form__input-register p-3">
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                disabled
              />
              <label htmlFor="email" className="form__label">Email</label>
            </div>
            <br />

            <div className="form__input-register p-3">
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Enter Your Name"
                autoFocus
              />
              <label htmlFor="name" className="form__label">Name</label>
            </div>
            <br />
            <div className="form__input-register p-3">
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Enter password"
                autoFocus
              />
              <label htmlFor="password" className="form__label">Password</label>
            </div>
            <br />
            <div className="form__input-register p-3">
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder="Confirm password"
                autoFocus
              />
              <label htmlFor="password" className="form__label">Confirm Password</label>
            </div>

            <br />
            <Button onClick={handleRegisterCompleteSubmit}  className="btn--register-complete p-4" size='medium'>
              Complete Registration
            </Button>
        </form>
    </div>

  )
}
export default RegistrationCompleteComponent;
