import React, { useEffect, Suspense } from 'react';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from 'react-router-dom';

import Register from './pages/auth/Register';
// import RegistrationComplete from './pages/auth/RegistrationComplete';
import Login from './pages/auth/Login';


import {currentUser} from './functions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './firebase';
import { PDFViewer } from '@react-pdf/renderer';
import Anonymous from './pages/auth/Anonymous';
import ForgotPassword from './pages/auth/ForgotPassword';
import UserManagement from './pages/auth/UserManagement';

// import SellerPublicProductList from './pages/seller/product/SellerPublicProductList'
// import SellerProductList from './pages/seller/product/SellerProductList'
// import SellerProductCreate from './pages/seller/product/SellerProductCreate';
// import SellerProductUpdate from './pages/seller/product/SellerProductUpdate';

// import SellerPodcasts from './pages/seller/video/SellerPodcasts';
// import SellerPublicVideoPage from './pages/seller/video/SellerPublicVideoPage';
// import SellerPodcast from './pages/seller/video/SellerPodcast';
// import SellerHomePage from './pages/seller/video/SellerHomePage';
// import SellerCreateHomePage from './pages/seller/SellerCreateHomePage';
// import SellerPodcastCreate from './pages/seller/video/SellerPodcastCreate';
// import SellerPodcastUpdate from './pages/seller/video/SellerPodcastUpdate';
// // import SellerVideoUpdate from './pages/seller/video/SellerVideoUpdate';

// import SellerOrderList from './pages/seller/order/SellerOrderList'
// // import DashboardPage from './pages/seller/SellerDashboardAnalysis'
// import SellerDetailOrderList from './pages/seller/SellerDetailOrderList'
// // import EditProfile from './pages/seller/EditProfile';
// import SellerDashboard from './pages/seller/SellerDashboard'
// import SellerPressRelease from './pages/seller/SellerPressRelease'
// // import SellerDashboardAnalysis from './pages/seller/SellerDashboardAnalysis'
// import SellerOrderDetails from './pages/seller/order/SellerOrderDetails';
// import SellerCreatePressReleaseAndCoupon from './pages/seller/SellerCreatePressReleaseAndCoupon';
// import SellerCustomersPictures from './pages/seller/SellerCustomersPictures';
// // import SellerPressReleaseRead from './pages/seller/SellerPressReleaseRead';
// import SellerProfile from './pages/seller/SellerProfile';
// import StripeCallback from './pages/seller/stripe/StripeCallback';


// import AdminDashboard from './pages/admin/AdminDashboard';
// import AdminDashboardAnalysis from './pages/admin/AdminDashboardAnalysis';
// // import ChatPage from './pages/ChatPage';
// import AdminOrderList from './pages/admin/AdminOrderList';
// import AdminOrderDetails from './pages/admin/AdminOrderDetails';
// import AdminUserList from './pages/admin/user/AdminUserList';
// import AdminUserEdit from './pages/admin/user/AdminUserEdit'

// import CategoryCreate from './pages/admin/category/CategoryCreate';
// import CategoryUpdate from './pages/admin/category/CategoryUpdate';
// import SubCreate from './pages/admin/sub/SubCreate';
// import BrandCreate from './pages/admin/sub/BrandCreate';
// import SubSubCreate from './pages/admin/sub/SubSubCreate';
// import SubSub3Create from './pages/admin/sub/SubSub3Create';
// import SubUpdate from './pages/admin/sub/SubUpdate';
// import BrandUpdate from './pages/admin/sub/BrandUpdate';
// import SubSubUpdate from './pages/admin/sub/SubSubUpdate';
// import SubSub3Update from './pages/admin/sub/SubSub3Update';
// import ProductCreate from './pages/admin/product/ProductCreate';
// import ProductUpdate from './pages/admin/product/ProductUpdate';
// import AllProducts from './pages/admin/product/AllProducts';
// import CreateCouponPage from './pages/admin/coupon/CreateCouponPage';
// import Payment from './pages/Payment';
// import CanceledPayment from './pages/CanceledPayment';

// import AdminSellersAllPodcasts from './pages/admin/video/AdminSellersAllPodcasts';
// import AdminPodcasts from './pages/admin/video/AdminPodcasts';
// import AdminCreatePodcast from './pages/admin/video/AdminCreatePodcast';
// import AdminPodcastUpdate from './pages/admin/video/AdminPodcastUpdate';

// import Home from './pages/Home';
// import Shop from './pages/Shop';
// import Videos from './pages/Videos';
// // import DashboardPage from './pages/DashboardPage';
// import Cart from './pages/Cart';
// import Checkout from './pages/Checkout'
// import Product from './pages/Product';
// import SingleVideoPage from './pages/SingleVideoPage';
// import SinglePodcastPage from './pages/SinglePodcastPage';
// import CategoryHome from './pages/category/CategoryHome';
// // import SellerCategoryHome from './components/cards/SellerCategoryHome';
// // import SubHome from './pages/sub/SubHome';


// import UserRoute from './components/routes/UserRoute';
// import AdminRoute from './components/routes/AdminRoute';
// import SellerRoute from './components/routes/SellerRoute';

// // import OrderDetails from './pages/user/OrderDetails';
// import CustomerOrderDetails from './pages/user/CustomerOrderDetails';
// import Profile from './pages/user/Profile';
// import BecomeSeller from './pages/user/BecomeSeller';
// import History from './pages/user/History';
// import PdfDisplay from './pages/user/PdfDisplay'
// // import Password from './pages/user/Password'
// import Wishlist from './pages/user/Wishlist'
// import Header from './components/nav/Header';
// import Footer from './components/nav/Footer';
// import ChatBox from './components/ChatBox';
// import SideDrawer from './components/drawer/SideDrawer';


// import Invoice from './components/order/Invoice';

const SellerPublicProductList = React.lazy(() => import( './pages/seller/product/SellerPublicProductList'))
const SellerProductList = React.lazy(() => import( './pages/seller/product/SellerProductList'))
const SellerProductCreate = React.lazy(() => import( './pages/seller/product/SellerProductCreate'))
const SellerProductUpdate = React.lazy(() => import( './pages/seller/product/SellerProductUpdate'))

const SellerPodcasts = React.lazy(() => import( './pages/seller/video/SellerPodcasts'))
const SellerPublicVideoPage = React.lazy(() => import( './pages/seller/video/SellerPublicVideoPage'))
const SellerPodcast = React.lazy(() => import( './pages/seller/video/SellerPodcast'))
const SellerHomePage = React.lazy(() => import( './pages/seller/video/SellerHomePage'))
const SellerCreateHomePage = React.lazy(() => import( './pages/seller/SellerCreateHomePage'))
const SellerPodcastCreate = React.lazy(() => import( './pages/seller/video/SellerPodcastCreate'))
const SellerPodcastUpdate = React.lazy(() => import( './pages/seller/video/SellerPodcastUpdate'))
// import SellerVideoUpdate from './pages/seller/video/SellerVideoUpdate'

const SellerOrderList = React.lazy(() => import( './pages/seller/order/SellerOrderList'))
// import DashboardPage from './pages/seller/SellerDashboardAnalysis'
const SellerDetailOrderList = React.lazy(() => import( './pages/seller/SellerDetailOrderList'))
// import EditProfile from './pages/seller/EditProfile'
const SellerDashboard = React.lazy(() => import( './pages/seller/SellerDashboard'))
const SellerPressRelease = React.lazy(() => import( './pages/seller/SellerPressRelease'))
// import SellerDashboardAnalysis from './pages/seller/SellerDashboardAnalysis'
const SellerOrderDetails = React.lazy(() => import( './pages/seller/order/SellerOrderDetails'))
const SellerCreatePressReleaseAndCoupon = React.lazy(() => import( './pages/seller/SellerCreatePressReleaseAndCoupon'))
const SellerCustomersPictures = React.lazy(() => import( './pages/seller/SellerCustomersPictures'))
// import SellerPressReleaseRead from './pages/seller/SellerPressReleaseRead'
const SellerProfile = React.lazy(() => import( './pages/seller/SellerProfile'))
const StripeCallback = React.lazy(() => import( './pages/seller/stripe/StripeCallback'))


const AdminDashboard = React.lazy(() => import( './pages/admin/AdminDashboard'))
const AdminDashboardAnalysis = React.lazy(() => import( './pages/admin/AdminDashboardAnalysis'))
// import ChatPage from './pages/ChatPage'
const AdminOrderList = React.lazy(() => import( './pages/admin/AdminOrderList'))
const AdminOrderDetails = React.lazy(() => import( './pages/admin/AdminOrderDetails'))
const AdminUserList = React.lazy(() => import( './pages/admin/user/AdminUserList'))
const AdminUserEdit = React.lazy(() => import( './pages/admin/user/AdminUserEdit'))

const CategoryCreate = React.lazy(() => import( './pages/admin/category/CategoryCreate'))
const CategoryUpdate = React.lazy(() => import( './pages/admin/category/CategoryUpdate'))
const SubCreate = React.lazy(() => import( './pages/admin/sub/SubCreate'))
const BrandCreate = React.lazy(() => import( './pages/admin/sub/BrandCreate'))
const SubSubCreate = React.lazy(() => import( './pages/admin/sub/SubSubCreate'))
const SubSub3Create = React.lazy(() => import( './pages/admin/sub/SubSub3Create'))
const SubUpdate = React.lazy(() => import( './pages/admin/sub/SubUpdate'))
const BrandUpdate = React.lazy(() => import( './pages/admin/sub/BrandUpdate'))
const SubSubUpdate = React.lazy(() => import( './pages/admin/sub/SubSubUpdate'))
const SubSub3Update = React.lazy(() => import( './pages/admin/sub/SubSub3Update'))
const ProductCreate = React.lazy(() => import( './pages/admin/product/ProductCreate'))
const ProductUpdate = React.lazy(() => import( './pages/admin/product/ProductUpdate'))
const AllProducts = React.lazy(() => import( './pages/admin/product/AllProducts'))
const CreateCouponPage = React.lazy(() => import( './pages/admin/coupon/CreateCouponPage'))
const Payment = React.lazy(() => import( './pages/Payment'))
const CanceledPayment = React.lazy(() => import( './pages/CanceledPayment'))

const AdminSellersAllPodcasts = React.lazy(() => import( './pages/admin/video/AdminSellersAllPodcasts'))
const AdminPodcasts = React.lazy(() => import( './pages/admin/video/AdminPodcasts'))
const AdminCreatePodcast = React.lazy(() => import( './pages/admin/video/AdminCreatePodcast'))
const AdminPodcastUpdate = React.lazy(() => import( './pages/admin/video/AdminPodcastUpdate'))

const Home = React.lazy(() => import( './pages/Home'))
const Shop = React.lazy(() => import( './pages/Shop'))
const Videos = React.lazy(() => import( './pages/Videos'))
// import DashboardPage from './pages/DashboardPage'
const Cart = React.lazy(() => import( './pages/Cart'))
const Checkout = React.lazy(() => import( './pages/Checkout'))
const Product = React.lazy(() => import( './pages/Product'))
const SingleVideoPage = React.lazy(() => import( './pages/SingleVideoPage'))
// const SinglePodcastPage = React.lazy(() => import( './pages/SinglePodcastPage'))
const CategoryHome = React.lazy(() => import( './pages/category/CategoryHome'))
// import SellerCategoryHome from './components/cards/SellerCategoryHome'
// import SubHome from './pages/sub/SubHome'


const UserRoute = React.lazy(() => import( './components/routes/UserRoute'))
const AdminRoute = React.lazy(() => import( './components/routes/AdminRoute'))
const SellerRoute = React.lazy(() => import( './components/routes/SellerRoute'))

// import OrderDetails from './pages/user/OrderDetails'
const CustomerOrderDetails = React.lazy(() => import( './pages/user/CustomerOrderDetails'))
const Profile = React.lazy(() => import( './pages/user/Profile'))
const BecomeSeller = React.lazy(() => import( './pages/user/BecomeSeller'))
const History = React.lazy(() => import( './pages/user/History'))
const PdfDisplay = React.lazy(() => import( './pages/user/PdfDisplay'))
// import Password from './pages/user/Password'
const Wishlist = React.lazy(() => import( './pages/user/Wishlist'))


//
// import './App.css'



const App = () => {
  const {user} = useSelector(state => ({...state}))
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
       // console.log('user what is now:', user)

        currentUser(idTokenResult.token)
          .then((res) => {
           // console.log('App res: ', res)
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                isSeller: res.data.isSeller,
                isAdmin: res.data.isAdmin,
                _id: res.data._id
              }
            })
          })
          .catch((err) => console.log(err));
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={
        <div className='background-single-product-reviews'>Loading.....</div>
      }>

      {/* <Header /> */}
      {/* <SideDrawer />
      <ToastContainer /> */}
         {/* <PDFViewer><Invoice/></PDFViewer> */}
      <Routes>
        {/* <Route element={<Anonymous />}> */}
          <Route path='/login' exact element={<Login />} />
          <Route path='/register' exact element={<Register/>} />
        {/* </Route> */}
        <Route path='/forgot/password' exact element={<ForgotPassword />} />
        <Route path='/user/management' exact element={<UserManagement />} />
          {/* <Route path='/register/complete' exact component={<RegistrationComplete />} /> */}
         {/*<Route path='/register/complete' exact element={<RegistrationComplete />} />*/}


        {/* <AdminRoute path='/dashboard' exact component={DashboardPage} /> */}
        {/* <AdminRoute path='/support' exact component={ChatPage} /> */}
        <Route path='/admin/dashboard' element={
          <AdminRoute path='/admin/dashboard' exact user={user} >
            <AdminDashboard/>
          </AdminRoute>

        } />
        <Route path='/admin/dashboardanalysis' element={
          <AdminRoute path='/admin/dashboardanalysis' exact user={user } >
            <AdminDashboardAnalysis/>
          </AdminRoute>

        }/>
        <Route path='/admin/orderlist/:id' element={
          <AdminRoute exact user={user} >
            <AdminOrderDetails/>
          </AdminRoute>


        }/>
        <Route path='/admin/orderlist' element={
          <AdminRoute path='/admin/orderlist' exact user={user} >
            <AdminOrderList/>
          </AdminRoute>

        }/>
        <Route path='/admin/userlist' element={
          <AdminRoute exact user={user} >
            <AdminUserList/>
          </AdminRoute>

        }/>
        <Route path='/admin/user/:id/edit' element={
          <AdminRoute exact user={user} >
            <AdminUserEdit/>
          </AdminRoute>

        }/>

        <Route path='/admin/products' element={
          <AdminRoute   exact user={user} >
            <AllProducts/>
          </AdminRoute>

        } />
        <Route path='/admin/productupdate/:slug' element={
          <AdminRoute AdminRoute exact user={user} >
            <ProductUpdate/>
          </AdminRoute>
        } />

        <Route path='/admin/product' element={
          <AdminRoute exact user={user} >
            <ProductCreate/>
          </AdminRoute>

        }/>
        <Route path='/admin/podcasts' element={
          <AdminRoute exact user={user}>
            <AdminPodcasts/>
          </AdminRoute>

      }/>
        <Route path='/admin/sellers-podcasts' element={
          <AdminRoute exact user={user}>
            <AdminSellersAllPodcasts/>
          </AdminRoute>

      }/>
        <Route path='/admin/podcast' element={
          <AdminRoute exact user={user} >
            <AdminCreatePodcast/>
          </AdminRoute>

        } />

        <Route path='/admin/podcast-update/:slug' element={
          <AdminRoute exact user={user} >
            <AdminPodcastUpdate/>
          </AdminRoute>

        }/>
        <Route path='/admin/coupon' element={
          <AdminRoute exact user={user} >
            <CreateCouponPage/>
          </AdminRoute>
      }/>


        <Route path='/admin/sub' element={
          <AdminRoute  exact user={user} >
            <SubCreate/>
          </AdminRoute>

      }/>


        <Route path='/admin/brand' element={
          <AdminRoute path='/admin/brand' exact user={user} >
            <BrandCreate/>
          </AdminRoute>

        } />
        <Route path='/admin/subSub' element={
          <AdminRoute exact user={user} >
            <SubSubCreate/>
          </AdminRoute>

        }/>
        <Route path='/admin/subSub3' element={
          <AdminRoute exact user={user} >
            <SubSub3Create/>
          </AdminRoute>
        }/>

        <Route path='/admin/sub/:slug' element={
          <AdminRoute  exact user={user} >
            <SubUpdate/>
          </AdminRoute>
        }/>
        <Route path='/admin/brand/:slug' element={
          <AdminRoute  exact user={user}>
            <BrandUpdate/>
          </AdminRoute>

        } />

        <Route path='/admin/sub-sub/:slug' element={
          <AdminRoute  exact user={user} >
            <SubSubUpdate/>
          </AdminRoute>
        } />

        <Route path='/admin/sub-sub3/:slug' element={
          <AdminRoute  exact user={user} >
            <SubSub3Update/>
          </AdminRoute>
        }/>
        <Route  path='/admin/sub-sub3/:slug' element={
          <AdminRoute  exact user={user}>
          </AdminRoute>
        }/>

        <Route path='/admin/category' element={
          <AdminRoute exact user={user} >
            <CategoryCreate/>
          </AdminRoute>
      } />

        <Route path='/admin/category/:slug' element={
          <AdminRoute exact user={user} >
            <CategoryUpdate/>
          </AdminRoute>
      } />



        <Route path='/:domain/productlist/:id' exact element={<SellerPublicProductList/>} />
        <Route path='/:domain/videos/:id' exact element={<SellerPublicVideoPage />} />
        <Route path='/:domain/podcast/:slug/:id' exact element={<SellerPodcast/>} />
        {/* <Route path='/:domain/single/podcast/:slug' exact element={<SinglePodcastPage/>} /> */}
        <Route path='/:domain/customers-pictures/:sellerId' exact element={<SellerCustomersPictures/>} />
        <Route path='/:domain/pressrelease/:id' exact element={<SellerPressRelease/>} />
        <Route path='/:domain/home' exact element={<SellerHomePage />} />
        <Route path='/seller/create/home' element={
          <SellerRoute path='/seller/create/home' exact user={user}>
            <SellerCreateHomePage/>
          </SellerRoute>

        }/>
        <Route path='/seller/create-podcast' element={
            <SellerRoute path='/seller/create-podcast' exact user={user} >
              <SellerPodcastCreate/>
            </SellerRoute>
          }
        />
        <Route path='/seller/update-podcast/:slug' element={
          <SellerRoute path='/seller/update-podcast/:slug' exact user={user} >
            <SellerPodcastUpdate/>
          </SellerRoute>

        }/>
        <Route path='/seller/podcasts' element={
          <SellerRoute path='/seller/podcasts'  user={user}>
            <SellerPodcasts />
          </SellerRoute>
          }
        />


        {/* <SellerRoute path='/seller/videoupdate/:slug' exact component={SellerVideoUpdate} /> */}
        <Route path='/seller/productlist' element={
          <SellerRoute path='/seller/productlist' exact user={user} >
            <SellerProductList />
          </SellerRoute>
          }
        />

        <Route  path='/seller/productcreate' element={
            <SellerRoute path='/seller/productcreate' exact user={user} >
              <SellerProductCreate/>
            </SellerRoute>
          }
        />
        <Route path='/seller/productupdate/:slug' element={
          <SellerRoute path='/seller/productupdate/:slug' exact user={user}>
            <SellerProductUpdate/>
          </SellerRoute>
        }/>
        {/* <SellerRoute path='/seller/dashboard/edit-profile' exact component={EditProfile} /> */}
        <Route path='/seller/dashboard' exact element={<SellerDashboard />} />
        <Route  path='/seller/detail-order-list' element={
          <SellerRoute path='/seller/detail-order-list' exact user={user } >
            <SellerDetailOrderList />
          </SellerRoute>

        }/>
        {/* <SellerRoute path='/seller/dashboardanalysis' exact component={SellerDashboardAnalysis} /> */}
        <Route path='/seller/orderlist' element={
          <SellerRoute path='/seller/orderlist' exact user={user}>
            <SellerOrderList/>
          </SellerRoute>

        }/>
        <Route path='/seller/orderlist/:id' element={
          <SellerRoute path='/seller/orderlist/:id' exact user={user} >
            <SellerOrderDetails/>
          </SellerRoute>

        }/>
        <Route path='/seller/press-release-coupon' element={
          <SellerRoute path='/seller/press-release-coupon' exact user={user}>
            <SellerCreatePressReleaseAndCoupon />
          </SellerRoute>

        }/>
        {/* <Route path='/seller/press-release/:id' exact component={SellerPressReleaseRead} /> */}
        <Route path='/:domain/profile/:id' exact element={<SellerProfile/>} />
        <Route path='/stripe/callback' exact element={<StripeCallback/>} />

         <Route path='/user/profile' element={
          <UserRoute path='/user/profile' user={user}>
             <Profile />
          </UserRoute>
          }
        />

        {/* <UserRoute path='/user/profile' exact component={Profile} /> */}
        {/* <UserRoute path='/user/profile' exact component={Profile} /> */}
        <Route path='/user/become-seller' exact element={
          <UserRoute path='/user/become-seller' user={user}>
            <BecomeSeller />
          </UserRoute>
        }
        />
        {/* <UserRoute path='/user/history' exact component={History} /> */}
         <Route path='/user/history' element={
          <UserRoute path='/user/history' user={user}>
             <History />
          </UserRoute>
          }
        />
         <Route path='/user/pdf-display/:id' element={
          <UserRoute  path='/user/pdf-display/:id' user={user}>
             <PdfDisplay />
          </UserRoute>
          }
        />
        <Route path='/user/orderlist/:id' element={
          <UserRoute exact user={user} >
            <CustomerOrderDetails/>
          </UserRoute>

        }/>
        {/* <UserRoute path='/user/password' exact component={Password} /> */}
        <Route path='/user/wishlist' element={
          <UserRoute path='/user/wishlist' user={user}>
             <Wishlist />
          </UserRoute>
          }
        />


        {/* <UserRoute path='/user/order/details/:id' exact component={OrderDetails} /> */}
             {/* <UserRoute path='/user/wishlist' exact element={<Wishlist />} */}


        <Route path='/' exact element={<Home/>} />

        <Route path='/payment/:id' exact element={<Payment/>} />
        <Route path='/canceled' exact element={<CanceledPayment/>} />
        <Route path='/checkout' exact element={<Checkout/>} />
        <Route path='/shop' exact element={<Shop />} />
        <Route path='/videos' exact element={<Videos />} />
        <Route path='/cart' exact element={<Cart/>} />
        {/* <Route path='/sub/:slug' exact component={SubHome} /> */}
        <Route path='/category/:name/:slug' exact element={<CategoryHome/>} />
        {/* <Route path='/seller/category/:slug' exact component={SellerCategoryHome} /> */}
         <Route path='/:domain/product/:slug' exact element={<Product/>} />
        <Route path='/:domain/video/:slug' exact element={<SingleVideoPage/>} />
        {/* /related-product/:location */}

      </Routes>
         {/* <footer className=" footer row text-center">
          {user && !user.isAdmin && <ChatBox user={user} />} */}
          {/* <div className='center'>All right reserved</div>{' '} */}
        {/* </footer> */}
      </Suspense>
    </React.Fragment>

  );
};

export default App;
