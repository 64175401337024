import React, { useState, useEffect } from 'react';
// import { app } from '../../firebase';
// import { getAuth, confirmPasswordReset } from 'firebase/auth'
import {useNavigate, useLocation} from'react-router-dom'
import { Button } from 'antd';
import {ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Header from '../nav/Header';
// import LoadingBox from '../../components/LoadingBox'

const ResetPasswordComponent = ({handleSubmit, newPassword, setNewPassword}) => {
  // const [newPassword, setNewPassword] = useState('');
  // const [succeed, setSucceed] = useState(false)
  const [loading, setLoading] = useState('');

  // const { user } = useSelector(state => ({ ...state }));

  // const auth = getAuth();
  // const navigate = useNavigate();
  // const params = useParams();
  // const { oobCode } = params;
  // const location = useLocation()


  // function useQuery() {
  //   const location = useLocation()
  //   return new URLSearchParams(location.search)
  // }

  // const query = useQuery()
  // const oobCode = query.get('oobCode');
  // const mode = query.get('mode');
  // const continueUrl = query.get('continueUrl');

  // console.log('-------------- oobCode ---------------', oobCode)
  // console.log('-------------- oobCode ---------------', query.get('oobCode'))
  // console.log('-------------- oobCode ---------------', query.get('continueUrl'))

//   useEffect(() => {
//     if (user && user.token) {
//       navigate('/')
//     }
//     if (succeed && succeed) {
//       // navigate('/login')
//     setTimeout(() => {
//        navigate('/login')
// }, 4000)
//     }
//   }, [succeed, user])










  // const handleSubmit = async(e) => {
  //   e.preventDefault()
  //   setLoading(true);

  //    const actionCodeSettings = {
  //     url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
  //     handleCodeInApp: true
  //   }


  //   confirmPasswordReset(auth, oobCode, newPassword)
  //     .then(() => {
  //       // setEmail('')
  //       // setLoading(false)
  //       toast.success(`You have reset your password successfully`)
  //       setSucceed(true)

  //     })
  //     .catch((error) => {
  //       console.log('-- error ---', error.message)
  //       toast.error('Password should be at least 6 characters')
  //       setLoading(false)
  //     })

  // }


  return (

      <div className="row-background-page">
       <div className='m-5 p-5'>
          {loading ?
          (<div className="spin-reset-password">Loading ...</div>)
          // (<LoadingBox></LoadingBox>)
          : (<h1 className="heading text-danger text-center">Reset Your Password</h1>)
        }
        <form onSubmit={handleSubmit} className='form'>
          <div className="form__input p-5">
            <input className="form-control p-2 m-5"
            type='password' value={newPassword}
            placeholder="Enter your new password"
            onChange={(e) => setNewPassword(e.target.value)}
            autoFocus
          />
          <br />
            <ToastContainer  />
              <Button
                onClick={handleSubmit}
                className='mb-3' type='primary'
                shape='round' block size='large'
                disabled={!newPassword}
              >
                Submit
              </Button>
          </div>
        </form>
       </div>
       </div>
    //    </div>
    //   </div>
    // </div>
  )
}

export default ResetPasswordComponent;

