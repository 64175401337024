import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';


const Search = () => {

  const { search } = useSelector((state) => ({ ...state }));
  const { text } = search;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    dispatch({
      type: "SEARCH_QUERY",
      payload: { text: e.target.value }
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/shop?${text}`);
  };

  return (
    <form className="form-inline " onSubmit={handleSubmit} >
      <input
        type="search"
        className="new-form-control"
        // style={{fontSize:'2rem'}}
        value={text}
        // placeholder="Search"
        onChange={handleChange}
      />

      <SearchOutlined onClick={handleSubmit}
        style={{ cursor: "pointer", fontSize: '2rem' }}
      />
    </form>
  )
}

export default Search

