import React from 'react'

const PageTitle = ({title, description}) => {



  return (
    <div >
      <h4 className="heading-primary--main-2-main-shop-page-title">{title}
        <p className="heading-primary--main-2-main-shop-page-title_description">{description}</p>
      </h4>
    </div>
  )
}

export default PageTitle;
