import axios from "axios";


export const register = async (name, email, authToken) => {
  return await axios.post(`${process.env.REACT_APP_API}/register`, { name, email}, {
    headers: {
      authToken: authToken
    }
  })
}

export const login = async (authToken) => {
  return await axios.post(`${process.env.REACT_APP_API}/login`, {}, {
    headers: {
      authToken: authToken
    }
  })
}



export const createOrUpdateUser = async (authToken) => {
  return await axios.post(`${process.env.REACT_APP_API}/create-or-update-user`, {}, {
    headers: {
      authToken: authToken
    }
  })
}

export const currentUser = async (authtoken) => {
  return await axios.post(`${process.env.REACT_APP_API}/current-user`, {}, {
    headers: {
      authtoken
    }
  })
}


// export const currentAdmin = async (authToken) => {
//   return await axios.post(`${process.env.REACT_APP_API}/current-admin`, {}, {
//     headers: {
//       authToken
//     }
//   })
// }

export const currentSeller = async (authToken) => {
  return await axios.post(`${process.env.REACT_APP_API}/current-seller`, {}, {
    headers: {
      authToken
    }
  })
}
