import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'
  // import { ToastContainer, toast } from 'react-toastify';


import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './css/style.css';
// import './css/icon-font.css';**


// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { PDFViewer } from '@react-pdf/renderer';
// import Invoice from './components/order/Invoice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const store = configureStore({ reducer: rootReducer })


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
 <Provider store={store}>
    <BrowserRouter>
      <Elements
        stripe={stripePromise}
        >

        <App />
        {/* <PDFViewer height={1000} width={1600} showToolbar>
          <Invoice />
        </PDFViewer> */}
      </Elements>
    </BrowserRouter>
  </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
