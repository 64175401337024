import React, { useState, useEffect } from 'react';
import { app } from '../../firebase';
import { getAuth, confirmPasswordReset, signInWithEmailLink, updatePassword } from 'firebase/auth'
import {useNavigate, useLocation} from'react-router-dom'
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import {ToastContainer, toast } from 'react-toastify';
import { register } from '../../functions/auth'
import Header from '../../components/nav/Header';
import ResetPasswordComponent from '../../components/userManagement/ResetPasswordComponent'
import RegistrationCompleteComponent from '../../components/userManagement/RegistrationCompleteComponent'
// import LoadingBox from '../../components/LoadingBox'

const UserManagement = () => {
  const [newPassword, setNewPassword] = useState('');
  const [succeed, setSucceed] = useState(false)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState('');

  const { user } = useSelector(state => ({ ...state }));

  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const params = useParams();
  // const { oobCode } = params;
  // const location = useLocation()


  function useQuery() {
    const location = useLocation()
    return new URLSearchParams(location.search)
  }

  const query = useQuery()
  const oobCode = query.get('oobCode');
  const mode = query.get('mode');
  const continueUrl = query.get('continueUrl');

  // console.log('-------------- oobCode ---------------', oobCode)
  // console.log('-------------- oobCode ---------------', query.get('oobCode'))
  // console.log('-------------- oobCode ---------------', query.get('continueUrl'))

  useEffect(() => {
    if (user && user.token) {
      navigate('/')
    }
    if (succeed && succeed) {
      // navigate('/login')
    setTimeout(() => {
       navigate('/login')
}, 4000)
    }
  }, [succeed, user])


    // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.

      // handleResetPassword(auth, actionCode, continueUrl, lang);
      break;
    // case 'recoverEmail':
    //   // Display email recovery handler and UI.
    //   handleRecoverEmail(auth, actionCode, lang);
    //   break;
    case 'signIn':
      // Display email verification handler and UI.
      // <RegistrationCompleteComponent
      //   auth={auth}
      //   signInWithEmailLink={signInWithEmailLink}
      //   updatePassword={updatePassword}
      // />

      break;
    default:
     console.log('----------------- swich case error ---------------')
  }
// }, false);







  const handleSubmit = async(e) => {
    e.preventDefault()
    setLoading(true);

    //  const actionCodeSettings = {
    //   url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
    //   handleCodeInApp: true
    // }


    confirmPasswordReset(auth, oobCode, newPassword)
      .then(() => {
        // setEmail('')
        // setLoading(false)
        toast.success(`You have reset your password successfully`)
        setSucceed(true)

      })
      .catch((error) => {
        console.log('-- error ---', error.message)
        toast.error('Password should be at least 6 characters')
        setLoading(false)
      })

  }

   const handleRegisterCompleteSubmit = async (e) => {
    e.preventDefault()
    if (!email || !password) {
      toast.error('Email and password are required!');
      return;
    }
    if (confirmPassword !== password) {
      toast.error("confirm password does not match password. Please try again");
      return;
    }
    if (password.length < 6) {
      toast.error('Password must be at least 6 characters')
    }

// console.log('----------------- auth --- currentUser------', auth)
      const result = await signInWithEmailLink(auth,email, window.location.href)
// console.log('----------------- result.user -------------', result && result.user)
      if (result.user.emailVerified) {
        window.localStorage.removeItem('emailForRegistration')
        let user = auth.currentUser;
        // console.log('----- user token 1--------', user && user.accessToken)

         updatePassword(password)
        //await user.updateProfile(name)
        const idTokenResult =user && user.accessToken;
      //  console.log('----- user token 2--------', idTokenResult)
        register(name, email, idTokenResult)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name:res.data.name,
                email: res.data.email,
                // token: idTokenResult.token,
                token: idTokenResult,
                role: res.data.role,
                _id: res.data._id
              }
            })
             navigate('/user/profile')
          })
        .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode)
        const errorMessage = error.message;
        console.log(errorMessage)
      })

      }

  }






  return (
    <div>
       <Header>
          <h1 className="heading-primary">
              <span className='heading-primary--main'>iDYANOVA
              </span>
              <span className='heading-primary--sub'>Best shopping online</span>
            </h1>
  <ToastContainer style={{width:'fitContent'}} />
            {/* <a href="/login" className='btn btn--white btn--animated'>Explore the world</a> */}
      </Header>
      <div className="row-background-page">
        <div className="book  m-5 ">
          {mode && mode === 'resetPassword' && (
            <ResetPasswordComponent
              newPassword={newPassword}
              handleSubmit={handleSubmit}
              setNewPassword={setNewPassword}
              // confirmPasswordReset={confirmPasswordReset}
              // oobCode={oobCode}

            />
          )}
          {mode && mode === 'signIn' && (
            <RegistrationCompleteComponent
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              // newPassword={newPassword}
              handleRegisterCompleteSubmit={handleRegisterCompleteSubmit}
              // setNewPassword={setNewPassword}
              // confirmPasswordReset={confirmPasswordReset}
              // oobCode={oobCode}

            />
          )}




       </div>
      </div>
    </div>
  )
}

export default UserManagement;

